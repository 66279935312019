import request from '../utils/http.service';

export const getStripeSetupLink = async (params) => {
  let account = null;
  try {
    const res = await request('/me/stripe-setup-link', 'POST', params);
    account = res.data;
  } catch (err) {
    account = err.data;
  }

  return account;
};

export const getStripeLoginLink = async () => {
  let account = null;
  try {
    const res = await request('/me/stripe-login-link', 'GET');
    account = res.data;
  } catch (err) {
    account = err.data;
  }

  return account;
};
