import { Types } from '../actions/credit-card.action';

const initialState = {
  cards: null,
  singleCard: null,
  loading: false,
};

const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_ALL_CARDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_ALL_CARDS_SUCCESS:
      return {
        ...state,
        cards: action.payload,
        loading: false,
      };
    case Types.GET_ALL_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case Types.CREATE_CARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.CREATE_CARD_SUCCESS:
      let newCards = [...state.cards, action.payload];
      if (action.payload.isDefault) {
        newCards = newCards.filter((card) => {
          if (card.creditCardId !== action.payload.creditCardId) {
            card.isDefault = false;
          }

          return card;
        });
      }

      return {
        ...state,
        cards: newCards,
        loading: false,
      };
    case Types.CREATE_CARD_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case Types.DELETE_CARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.DELETE_CARD_SUCCESS:
      return {
        ...state,
        cards: [...state.cards].filter(
          (card) => card.creditCardId !== action.payload.creditCardId
        ),
        loading: false,
      };
    case Types.DELETE_CARD_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case Types.GET_SINGLE_CARD_REQUEST:
      return {
        ...state,
        singleCard: null,
        loading: true,
      };
    case Types.GET_SINGLE_CARD_SUCCESS:
      return {
        ...state,
        singleCard: action.payload,
        loading: false,
      };
    case Types.GET_SINGLE_CARD_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case Types.SET_DEFAULT_CARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.SET_DEFAULT_CARD_SUCCESS:
      return {
        ...state,
        cards: [...state.cards].map((card) => {
          if (card.creditCardId === action.payload.creditCardId) {
            card.isDefault = true;
          } else {
            card.isDefault = false;
          }

          return card;
        }),
        loading: false,
      };
    case Types.SET_DEFAULT_CARD_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default cardReducer;
