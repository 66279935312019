import React from 'react';
import FooterContainer from './index.style';

const today = new Date();

const Footer = () => {
  return (
    <FooterContainer className="footer">
      <div className="container-fluid">
        <span className="copyright text-center">
          © BizCoreX Inc. All rights Reserved.
          {/* {today.getFullYear()} */}
        </span>
      </div>
    </FooterContainer>
  );
};

export default Footer;
