import React from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { faHome, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { menuList } from '../../config/constant';
import NavbarContainer from './index.style';

const Navbar = ({ logout, sidebarOpen, toggleMenu }) => {
  let { pathname } = useLocation();

  let pathArr = pathname.split('/', 3);
  let paths = pathArr.filter((el, idx) => pathArr.indexOf(el) === idx);

  const getMenuObj = (path) => {
    let val = menuList.find((menu) => menu.path.includes(path));
    if (val === undefined) return '';
    return val;
  };

  return (
    <NavbarContainer
      className={`${sidebarOpen && 'side-open'} navbar navbar-expand-lg`}
    >
      <div className="container-fluid">
        <div className="navbar-inner">
          <div className="hamburger-menu" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} />
          </div>

          <span className="navbar-brand">
            <Breadcrumb>
              {paths.map((path, id) => (
                <Breadcrumb.Item key={id} href={`${getMenuObj(path).path}`}>
                  {id === 0 ? (
                    <FontAwesomeIcon icon={faHome} />
                  ) : (
                    getMenuObj(path).name
                  )}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </span>

          <div className="justify-content-end logout-btn" id="navigation">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <div onClick={logout}>
                  <span className="no-icon">Log out</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </NavbarContainer>
  );
};

export default Navbar;
