export const Types = {
  GET_STORES_REQUEST: 'GET_STORES_REQUEST',
  GET_STORES_SUCCESS: 'GET_STORES_SUCCESS',
  GET_STORES_FAILURE: 'GET_STORES_FAILURE',

  CREATE_STORE_REQUEST: 'CREATE_STORE_REQUEST',
  CREATE_STORE_SUCCESS: 'CREATE_STORE_SUCCESS',
  CREATE_STORE_FAILURE: 'CREATE_STORE_FAILURE',

  UPDATE_STORE_REQUEST: 'UPDATE_STORE_REQUEST',
  UPDATE_STORE_SUCCESS: 'UPDATE_STORE_SUCCESS',
  UPDATE_STORE_FAILURE: 'UPDATE_STORE_FAILURE',

  DELETE_STORE_REQUEST: 'DELETE_STORE_REQUEST',
  DELETE_STORE_SUCCESS: 'DELETE_STORE_SUCCESS',
  DELETE_STORE_FAILURE: 'DELETE_STORE_FAILURE',

  GET_SINGLE_STORE_REQUEST: 'GET_SINGLE_STORE_REQUEST',
  GET_SINGLE_STORE_SUCCESS: 'GET_SINGLE_STORE_SUCCESS',
  GET_SINGLE_STORE_FAILURE: 'GET_SINGLE_STORE_FAILURE',

  GET_STORE_TAX_REQUEST: 'GET_STORE_TAX_REQUEST',
  GET_STORE_TAX_SUCCESS: 'GET_STORE_TAX_SUCCESS',
  GET_STORE_TAX_FAILURE: 'GET_STORE_TAX_FAILURE',

  STORE_SUBSCRIBE_REQUEST: 'STORE_SUBSCRIBE_REQUEST',
  STORE_SUBSCRIBE_SUCCESS: 'STORE_SUBSCRIBE_SUCCESS',
  STORE_SUBSCRIBE_FAILURE: 'STORE_SUBSCRIBE_FAILURE',

  GET_INVOICES_REQUEST: 'GET_INVOICES_REQUEST',
  GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
  GET_INVOICES_FAILURE: 'GET_INVOICES_FAILURE',
};

export const getStoresRequest = () => ({
  type: Types.GET_STORES_REQUEST,
  payload: {},
});
export const getStoresSuccess = (data) => ({
  type: Types.GET_STORES_SUCCESS,
  payload: data,
});
export const getStoresFailure = (data) => ({
  type: Types.GET_STORES_FAILURE,
  payload: data,
});

export const createStoreRequest = (data) => ({
  type: Types.CREATE_STORE_REQUEST,
  payload: data,
});
export const createStoreSuccess = (data) => ({
  type: Types.CREATE_STORE_SUCCESS,
  payload: data,
});
export const createStoreFailure = (data) => ({
  type: Types.CREATE_STORE_FAILURE,
  payload: data,
});

export const updateStoreRequest = (data) => ({
  type: Types.UPDATE_STORE_REQUEST,
  payload: data,
});
export const updateStoreSuccess = (data) => ({
  type: Types.UPDATE_STORE_SUCCESS,
  payload: data,
});
export const updateStoreFailure = (data) => ({
  type: Types.UPDATE_STORE_FAILURE,
  payload: data,
});

export const deleteStoreRequest = (data) => ({
  type: Types.DELETE_STORE_REQUEST,
  payload: data,
});
export const deleteStoreSuccess = (data) => ({
  type: Types.DELETE_STORE_SUCCESS,
  payload: data,
});
export const deleteStoreFailure = (data) => ({
  type: Types.DELETE_STORE_FAILURE,
  payload: data,
});

export const getSingleStoreRequest = (data) => ({
  type: Types.GET_SINGLE_STORE_REQUEST,
  payload: data,
});
export const getSingleStoreSuccess = (data) => ({
  type: Types.GET_SINGLE_STORE_SUCCESS,
  payload: data,
});
export const getSingleStoreFailure = (data) => ({
  type: Types.GET_SINGLE_STORE_FAILURE,
  payload: data,
});

export const getTaxRequest = (data) => ({
  type: Types.GET_STORE_TAX_REQUEST,
  payload: data,
});
export const getTaxSuccess = (data) => ({
  type: Types.GET_STORE_TAX_SUCCESS,
  payload: data,
});
export const getTaxFailure = (data) => ({
  type: Types.GET_STORE_TAX_FAILURE,
  payload: data,
});

export const storeSubscribeRequest = (data) => ({
  type: Types.STORE_SUBSCRIBE_REQUEST,
  payload: data,
});
export const storeSubscribeSuccess = (data) => ({
  type: Types.STORE_SUBSCRIBE_SUCCESS,
  payload: data,
});
export const storeSubscribeFailure = (data) => ({
  type: Types.STORE_SUBSCRIBE_FAILURE,
  payload: data,
});

export const getInvoicesRequest = (data) => ({
  type: Types.GET_INVOICES_REQUEST,
  payload: data,
});
export const getInvoicesSuccess = (data) => ({
  type: Types.GET_INVOICES_SUCCESS,
  payload: data,
});
export const getInvoicesFailure = (data) => ({
  type: Types.GET_INVOICES_FAILURE,
  payload: data,
});
