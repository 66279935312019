import styled from '@emotion/styled';

const NavbarContainer = styled.div`
  z-index: 998;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  box-shadow: 0 0 2rem 0 rgba(53, 64, 82, 0.1);
  padding: 0;
  margin-left: 0;
  transition: margin 0.3s;
  background-color: ${(props) => props.theme.colors.white};

  &.side-open {
    @media (min-width: 1024px) {
      margin-left: 16rem;
      width: calc(100% - 16rem);
    }
  }

  .navbar-inner {
    width: 100%;
    display: flex;
    height: 100%;
    padding: 0 1.25rem;
    align-items: center;

    @media (min-width: 1024px) {
      padding: 0 0.5rem;
    }

    .nav-item {
      div {
        cursor: pointer;
      }

      .no-icon {
        &:hover,
        &:focus {
          color: ${(props) => props.theme.colors.persianBlue};
        }
      }
    }
    .navbar-brand {
      padding-left: 1.5rem;
    }
  }

  .hamburger-menu {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover,
    &:focus {
      color: ${(props) => props.theme.colors.persianBlue};
    }
  }

  .breadcrumb {
    margin: 0;
    background-color: ${(props) => props.theme.colors.white};

    .breadcrumb-item {
      align-items: center;
      font-size: 1rem;

      &:first-of-type {
        &:before {
          content: none;
        }
      }

      &:before {
        content: '';
        padding: 0;
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        vertical-align: middle;
        background: #828d99;
        margin-right: 0.5rem;
      }

      &.active {
        a {
          color: #828d99;

          &:hover,
          &:focus {
            color: #828d99;
          }
        }
      }

      a {
        color: #304156;
        text-decoration: none;

        &:hover,
        &:focus {
          color: ${(props) => props.theme.colors.persianBlue};
        }
      }
    }
  }

  .logout-btn {
    position: absolute;
    right: 15px;
  }
`;

export default NavbarContainer;
