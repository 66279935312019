import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions/stores.action';
import * as api from '../../api/stores.api';

function* getStores() {
  try {
    const result = yield call(api.getStores);

    if (result) {
      yield put(actions.getStoresSuccess(result));
    } else {
      yield put(actions.getStoresFailure(result));
    }
  } catch (error) {
    yield put(actions.getStoresFailure(error));
  }
}

function* createStore(action) {
  try {
    const result = yield call(api.createStore, action.payload);

    if (result) {
      yield put(actions.createStoreSuccess(result));
    } else {
      yield put(actions.createStoreFailure(result));
    }
  } catch (error) {
    yield put(actions.createStoreFailure(error));
  }
}

function* updateStore(action) {
  try {
    const result = yield call(api.updateStore, action.payload);

    if (result) {
      yield put(actions.updateStoreSuccess(result));
    } else {
      yield put(actions.updateStoreFailure(result));
    }
  } catch (error) {
    yield put(actions.updateStoreFailure(error));
  }
}

function* deleteStore(action) {
  try {
    const result = yield call(api.deleteStore, action.payload);

    if (result) {
      yield put(actions.deleteStoreSuccess(result));
    } else {
      yield put(actions.deleteStoreFailure(result));
    }
  } catch (error) {
    yield put(actions.deleteStoreFailure(error));
  }
}

function* getSingleStore(action) {
  try {
    const result = yield call(api.getSingleStore, action.payload);

    if (result) {
      yield put(actions.getSingleStoreSuccess(result));
    } else {
      yield put(actions.getSingleStoreFailure(result));
    }
  } catch (error) {
    yield put(actions.getSingleStoreFailure(error));
  }
}

function* getStoreTax(action) {
  try {
    const result = yield call(api.getStoreTax, action.payload);

    if (result) {
      yield put(actions.getTaxSuccess(result));
    } else {
      yield put(actions.getTaxFailure(result));
    }
  } catch (error) {
    yield put(actions.getTaxFailure(error));
  }
}

function* updateStorePlan(action) {
  try {
    const result = yield call(api.subscribeStore, action.payload);

    if (result) {
      yield put(actions.storeSubscribeSuccess(result));
    } else {
      yield put(actions.storeSubscribeFailure(result));
    }
  } catch (error) {
    yield put(actions.storeSubscribeFailure(error));
  }
}

function* getInvoices(action) {
  try {
    const result = yield call(api.getStoreInvoices, action.payload);

    if (result) {
      yield put(actions.getInvoicesSuccess(result));
    } else {
      yield put(actions.getInvoicesFailure(result));
    }
  } catch (error) {
    yield put(actions.getInvoicesFailure(error));
  }
}

const watchMyAddresses = [
  takeEvery(actions.Types.GET_STORES_REQUEST, getStores),
  takeEvery(actions.Types.CREATE_STORE_REQUEST, createStore),
  takeEvery(actions.Types.UPDATE_STORE_REQUEST, updateStore),
  takeEvery(actions.Types.DELETE_STORE_REQUEST, deleteStore),
  takeEvery(actions.Types.GET_STORE_TAX_REQUEST, getStoreTax),
  takeEvery(actions.Types.GET_INVOICES_REQUEST, getInvoices),
  takeEvery(actions.Types.STORE_SUBSCRIBE_REQUEST, updateStorePlan),
  takeEvery(actions.Types.GET_SINGLE_STORE_REQUEST, getSingleStore),
];

export default watchMyAddresses;
