import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions/stripe-setting.action';
import * as api from '../../api/stripe-setting.api';

function* getStripeSetupLink(action) {
  try {
    const result = yield call(api.getStripeSetupLink, action.payload);

    if (result) {
      yield put(actions.getStripeSetupLinkSuccess(result));
    } else {
      yield put(actions.getStripeSetupLinkFailure(result));
    }
  } catch (error) {
    yield put(actions.getStripeSetupLinkFailure(error));
  }
}

function* getStripeLoginLink() {
  try {
    const result = yield call(api.getStripeLoginLink);

    if (result) {
      yield put(actions.getStripeLoginLinkSuccess(result));
    } else {
      yield put(actions.getStripeLoginLinkFailure(result));
    }
  } catch (error) {
    yield put(actions.getStripeLoginLinkFailure(error));
  }
}

const watchStripeSetting = [
  takeEvery(actions.Types.GET_STRIPE_SETUP_LINK_REQUEST, getStripeSetupLink),
  takeEvery(actions.Types.GET_STRIPE_LOGIN_LINK_REQUEST, getStripeLoginLink),
];

export default watchStripeSetting;
