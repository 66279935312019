import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import config from './config/config';

//const tenantSubdomain = config.b2cTenant.split('.')[0];
const instance = `https://${config.b2cInstance}/tfp/`;
const signInAuthority = `${instance}${config.b2cTenant}/${config.b2cSigninPolicy}/`;

const configuration = {
  auth: {
    authority: signInAuthority,
    clientId: config.b2cAppID,
    // clientId: "2e40b727-c1d0-4dee-8553-72ed04519bd1",
    knownAuthorities: [`${config.b2cInstance}`],
    redirectUri: window.location.origin,
    // redirectUri: "msal2e40b727-c1d0-4dee-8553-72ed04519bd1://auth",
    postLogoutRedirectUri: window.location.origin,
    validateAuthority: false,
  },
  // cache: {
  //   cacheLocation: 'localStorage',
  //   storeAuthStateInCookie: false,
  // },
};
const pca = new PublicClientApplication(configuration);

ReactDOM.render(
  <Provider store={store}>
    <MsalProvider instance={pca}>
      <App />
    </MsalProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
