import request from '../utils/http.service';

export const getProfile = async () => {
  let account = null;
  try {
    const res = await request('/me/profile', 'GET');
    account = res.data;
  } catch (err) {
    account = err.data;
  }

  return account;
};
