import request from '../utils/http.service';

export const getPrices = async () => {
  let prices = null;
  try {
    const res = await request(`/prices`, 'GET');
    prices = res.data;
  } catch (err) {
    prices = err.data;
  }

  return prices;
};
