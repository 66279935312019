import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions/billing-info.action';
import * as api from '../../api/billing-info.api';

function* getBillInfo() {
  try {
    const result = yield call(api.getBillingInfo);

    if (result) {
      yield put(actions.getBillInfoSuccess(result));
    } else {
      yield put(actions.getBillInfoFailure(result));
    }
  } catch (error) {
    yield put(actions.getBillInfoFailure(error));
  }
}

function* updateBillInfo(action) {
  try {
    const result = yield call(api.updateBillingInfo, action.payload);

    if (result) {
      yield put(actions.updateBillInfoSuccess(result));
    } else {
      yield put(actions.updateBillInfoFailure(result));
    }
  } catch (error) {
    yield put(actions.updateBillInfoFailure(error));
  }
}

const watchBillingInfo = [
  takeEvery(actions.Types.GET_BILL_INFO_REQUEST, getBillInfo),
  takeEvery(actions.Types.UPDATE_BILL_INFO_REQUEST, updateBillInfo),
];

export default watchBillingInfo;
