export const Types = {
  GET_INVOICES_REQUEST: 'GET_INVOICES_REQUEST',
  GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
  GET_INVOICES_FAILURE: 'GET_INVOICES_FAILURE',
};

export const getInvoicesRequest = () => ({
  type: Types.GET_INVOICES_REQUEST,
  payload: {},
});
export const getInvoicesSuccess = (data) => ({
  type: Types.GET_INVOICES_SUCCESS,
  payload: data,
});
export const getInvoicesFailure = (data) => ({
  type: Types.GET_INVOICES_FAILURE,
  payload: data,
});
