export const Types = {
  GET_STRIPE_SETUP_LINK_REQUEST: 'GET_STRIPE_SETUP_LINK_REQUEST',
  GET_STRIPE_SETUP_LINK_SUCCESS: 'GET_STRIPE_SETUP_LINK_SUCCESS',
  GET_STRIPE_SETUP_LINK_FAILURE: 'GET_STRIPE_SETUP_LINK_FAILURE',

  GET_STRIPE_LOGIN_LINK_REQUEST: 'GET_STRIPE_LOGIN_LINK_REQUEST',
  GET_STRIPE_LOGIN_LINK_SUCCESS: 'GET_STRIPE_LOGIN_LINK_SUCCESS',
  GET_STRIPE_LOGIN_LINK_FAILURE: 'GET_STRIPE_LOGIN_LINK_FAILURE',
};

export const getStripeSetupLinkRequest = (data) => ({
  type: Types.GET_STRIPE_SETUP_LINK_REQUEST,
  payload: data,
});
export const getStripeSetupLinkSuccess = (data) => ({
  type: Types.GET_STRIPE_SETUP_LINK_SUCCESS,
  payload: data,
});
export const getStripeSetupLinkFailure = (data) => ({
  type: Types.GET_STRIPE_SETUP_LINK_FAILURE,
  payload: data,
});

export const getStripeLoginLinkRequest = () => ({
  type: Types.GET_STRIPE_LOGIN_LINK_REQUEST,
  payload: {},
});
export const getStripeLoginLinkSuccess = (data) => ({
  type: Types.GET_STRIPE_LOGIN_LINK_SUCCESS,
  payload: data,
});
export const getStripeLoginLinkFailure = (data) => ({
  type: Types.GET_STRIPE_LOGIN_LINK_FAILURE,
  payload: data,
});
