import React, { useState } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Accordion, Nav } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

const SubMenu = ({ title, icon, items, toggleMenu }) => {
  let location = useLocation();
  let initialCollapsed = items.some((item) => item.path === location.pathname);
  const [openCollapse, setOpenCollapse] = useState(initialCollapsed);

  return (
    <Nav.Item className={classNames({ open: openCollapse })}>
      <Accordion defaultActiveKey={`${openCollapse ? 0 : -1}`}>
        <Accordion.Toggle
          as={Nav.Link}
          variant="link"
          eventKey="0"
          onClick={() => setOpenCollapse(!openCollapse)}
        >
          <div>
            {icon && <FontAwesomeIcon icon={icon} />}
            {title}
          </div>
          <FontAwesomeIcon
            icon={faChevronUp}
            style={{
              transition: 'transform .2s',
              transform: `rotate(${!openCollapse ? 180 : 0}deg)`,
            }}
          />
        </Accordion.Toggle>

        <Accordion.Collapse eventKey="0">
          <nav className="nav flex-column">
            {items.map((item, id) => (
              <div
                key={id}
                className="menu-item"
                onClick={window.innerWidth < 1024 ? toggleMenu : undefined}
              >
                <NavLink
                  exact
                  className="menu-link"
                  to={item.path}
                  activeClassName="active"
                >
                  {item.icon && <FontAwesomeIcon icon={item.icon} />}
                  {item.name}
                </NavLink>
              </div>
            ))}
          </nav>
        </Accordion.Collapse>
      </Accordion>
    </Nav.Item>
  );
};

export default SubMenu;
