export const Types = {
  GET_BUSINESS_PROFILE_REQUEST: 'GET_BUSINESS_PROFILE_REQUEST',
  GET_BUSINESS_PROFILE_SUCCESS: 'GET_BUSINESS_PROFILE_SUCCESS',
  GET_BUSINESS_PROFILE_FAILURE: 'GET_BUSINESS_PROFILE_FAILURE',

  CREATE_BUSINESS_PROFILE_REQUEST: 'CREATE_BUSINESS_PROFILE_REQUEST',
  CREATE_BUSINESS_PROFILE_SUCCESS: 'CREATE_BUSINESS_PROFILE_SUCCESS',
  CREATE_BUSINESS_PROFILE_FAILURE: 'CREATE_BUSINESS_PROFILE_FAILURE',

  UPDATE_BUSINESS_PROFILE_REQUEST: 'UPDATE_BUSINESS_PROFILE_REQUEST',
  UPDATE_BUSINESS_PROFILE_SUCCESS: 'UPDATE_BUSINESS_PROFILE_SUCCESS',
  UPDATE_BUSINESS_PROFILE_FAILURE: 'UPDATE_BUSINESS_PROFILE_FAILURE',
};

export const getBusinessProfileRequest = () => ({
  type: Types.GET_BUSINESS_PROFILE_REQUEST,
  payload: {},
});
export const getBusinessProfileSuccess = (data) => ({
  type: Types.GET_BUSINESS_PROFILE_SUCCESS,
  payload: data,
});
export const getBusinessProfileFailure = (data) => ({
  type: Types.GET_BUSINESS_PROFILE_FAILURE,
  payload: data,
});

export const createBusinessProfileRequest = (data) => ({
  type: Types.CREATE_BUSINESS_PROFILE_REQUEST,
  payload: data,
});
export const createBusinessProfileSuccess = (data) => ({
  type: Types.CREATE_BUSINESS_PROFILE_SUCCESS,
  payload: data,
});
export const createBusinessProfileFailure = (data) => ({
  type: Types.CREATE_BUSINESS_PROFILE_FAILURE,
  payload: data,
});

export const updateBusinessProfileRequest = (data) => ({
  type: Types.UPDATE_BUSINESS_PROFILE_REQUEST,
  payload: data,
});
export const updateBusinessProfileSuccess = (data) => ({
  type: Types.UPDATE_BUSINESS_PROFILE_SUCCESS,
  payload: data,
});
export const updateBusinessProfileFailure = (data) => ({
  type: Types.UPDATE_BUSINESS_PROFILE_FAILURE,
  payload: data,
});
