export const Types = {
  GET_PRICES_REQUEST: 'GET_PRICES_REQUEST',
  GET_PRICES_SUCCESS: 'GET_PRICES_SUCCESS',
  GET_PRICES_FAILURE: 'GET_PRICES_FAILURE',
};

export const getPricesRequest = () => ({
  type: Types.GET_PRICES_REQUEST,
  payload: {},
});

export const getPricesSuccess = (data) => ({
  type: Types.GET_PRICES_SUCCESS,
  payload: data,
});

export const getPricesFailure = (data) => ({
  type: Types.GET_PRICES_FAILURE,
  payload: data,
});
