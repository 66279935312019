import request from '../utils/http.service';

export const getBillingInfo = async () => {
  let info = null;
  try {
    const res = await request(`/me/billing-information`, 'GET');
    info = res.data;
  } catch (err) {
    info = err.data;
  }

  return info;
};

export const updateBillingInfo = async (param) => {
  let info = null;
  try {
    const res = await request('/me/billing-information', 'PUT', param);
    info = res.data;
  } catch (err) {
    info = err.data;
  }

  return info;
};
