import request from '../utils/http.service';

export const getInvoices = async () => {
  let invoices = null;
  try {
    const res = await request('/me/invoices', 'GET');
    invoices = res.data;
  } catch (err) {
    invoices = err.data;
  }

  return invoices;
};
