import { Types } from '../actions/stores.action';

const initialState = {
  stores: null,
  singleStore: null,
  storeTax: null,
  invoices: null,
  loading: false,
  error: null,
};

const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_STORES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case Types.GET_STORES_SUCCESS:
      return {
        ...state,
        stores: action.payload,
        loading: false,
      };
    case Types.GET_STORES_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case Types.CREATE_STORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.CREATE_STORE_SUCCESS:
      return {
        ...state,
        stores: [...state.stores, action.payload],
        loading: false,
      };
    case Types.CREATE_STORE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case Types.UPDATE_STORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.UPDATE_STORE_SUCCESS:
      let newStores = [...state.stores].map((store) =>
        store.id === action.payload.id ? action.payload : store
      );

      return {
        ...state,
        addresses: newStores,
        loading: false,
      };
    case Types.UPDATE_STORE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case Types.DELETE_STORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.DELETE_STORE_SUCCESS:
      return {
        ...state,
        stores: [...state.stores].filter(
          (store) => store.id !== action.payload.id
        ),
        loading: false,
      };
    case Types.DELETE_STORE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case Types.GET_SINGLE_STORE_REQUEST:
      return {
        ...state,
        singleStore: null,
        loading: true,
      };
    case Types.GET_SINGLE_STORE_SUCCESS:
      return {
        ...state,
        singleStore: action.payload,
        loading: false,
      };
    case Types.GET_SINGLE_STORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'This is not your store.',
      };

    case Types.GET_STORE_TAX_REQUEST:
      return {
        ...state,
        storeTax: null,
        loading: true,
      };
    case Types.GET_STORE_TAX_SUCCESS:
      return {
        ...state,
        storeTax: action.payload,
        loading: false,
      };
    case Types.GET_STORE_TAX_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case Types.STORE_SUBSCRIBE_REQUEST:
      return {
        ...state,
        singleStore: null,
        loading: true,
      };
    case Types.STORE_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        singleStore: action.payload,
        loading: false,
      };
    case Types.STORE_SUBSCRIBE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case Types.GET_INVOICES_REQUEST:
      return {
        ...state,
        storeTax: null,
        loading: true,
      };
    case Types.GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        loading: false,
      };
    case Types.GET_INVOICES_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default storeReducer;
