import request from '../utils/http.service';

export const getStores = async () => {
  let stores = null;
  try {
    const res = await request(`/me/stores`, 'GET');
    stores = res.data;
  } catch (err) {
    stores = err.data;
  }

  return stores;
};

export const createStore = async (param) => {
  let store = null;
  try {
    const res = await request('/me/stores', 'POST', param);
    store = res.data;
  } catch (err) {
    store = err.data;
  }

  return store;
};

export const getSingleStore = async (param) => {
  let store = null;
  try {
    const res = await request(`/me/stores/${param}`, 'GET');
    store = res.data;
  } catch (err) {
    store = err.data;
  }

  return store;
};
export const updateStore = async (param) => {
  let store = null;
  try {
    const res = await request(`/me/stores/${param.id}`, 'PUT', param);
    store = res.data;
  } catch (err) {
    store = err.data;
  }

  return store;
};

export const deleteStore = async (param) => {
  let store = null;
  try {
    const res = await request(`/me/stores/${param}`, 'DELETE');
    store = res.data;
  } catch (err) {
    store = err.data;
  }

  return store;
};

export const subscribeStore = async (param) => {
  let store = null;

  try {
    const res = await request(
      `/me/stores/${param.storeId}/subscribe`,
      'POST',
      param
    );
    store = res.data;
  } catch (err) {
    store = err.data;
  }

  return store;
};

export const getStoreTax = async (param) => {
  let tax = null;

  try {
    const res = await request(`/me/stores/${param}/tax-info`, 'GET');
    tax = res.data;
  } catch (err) {
    tax = err.data;
  }

  return tax;
};

export const getStoreInvoices = async (param) => {
  let invoices = null;

  try {
    const res = await request(`/me/stores/${param}/invoices`, 'GET');
    invoices = res.data;
  } catch (err) {
    invoices = err.data;
  }

  return invoices;
};
