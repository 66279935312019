import styled from '@emotion/styled';

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 999;

  background: #242939;
  color: ${(props) => props.theme.colors.white};

  display: block;
  order: -1;
  width: 0;
  padding: 0;
  box-shadow: none;
  transition: box-shadow 0.3s 0.15s, transform 0.3s, margin-left 0.3s,
    margin-right 0.3s, width 0.3s, z-index 0s ease 0.3s;

  &.open {
    width: 16rem;
    flex: 0 0 16rem;
    display: flex;
    flex-direction: column;

    .sidebar-header,
    .sidebar-nav {
      display: block;
    }
  }

  a {
    color: ${(props) => props.theme.colors.white};
  }

  .sidebar-header {
    display: none;
    position: relative;
    padding: 1.5rem;
    text-align: center;

    img {
      width: 10rem;
    }

    svg {
      width: 20px;
      height: 20px;
      color: rgba(255, 255, 255, 0.8);
      position: absolute;
      top: 1.25rem;
      right: 1.25rem;

      @media (min-width: 1024px) {
        display: none;
      }
    }
  }

  .sidebar-nav {
    padding-left: 0;
    padding-bottom: 3.5rem;
    list-style: none;
    display: none;

    .nav-item {
      position: relative;
      background: transparent;
      transition: background 50ms ease-in-out 0s;
      cursor: pointer;

      &.active {
        & > .menu-link {
          background: hsla(0, 0%, 100%, 0.2);
          border-left: 3px solid #eee;
        }
      }

      &.open {
        background-color: ${(props) => props.theme.colors.darkBlue};
      }

      .nav-link,
      .menu-link {
        display: flex;
        flex: 1 1;
        align-items: center;
        font-size: 0.875rem;
        padding: 0.75rem 1.5rem;
        color: rgba(255, 255, 255, 0.85);
        cursor: pointer;
        white-space: nowrap;
        border-left: 3px solid transparent;

        &:hover,
        &:focus {
          text-decoration: none;
          color: #fff;
          background: hsla(0, 0%, 100%, 0.13);
        }

        &.active {
          background: hsla(0, 0%, 100%, 0.2);
          border-left: 3px solid #eee;
        }
      }

      .accordion {
        a {
          div {
            svg {
              margin-right: 0.75rem;
              width: 16px;
              text-align: center;
            }
          }
        }
      }

      .menu-link {
        svg {
          margin-right: 0.75rem;
          width: 16px;
          text-align: center;
        }
      }

      .nav-link {
        justify-content: space-between;
      }

      span {
        svg {
          width: 0.75rem;
          transition: all 0.2s ease-out 0s;
        }
      }

      .child-menu {
        padding-left: 0;
        list-style: none;

        a {
        }
      }
    }
  }
`;

export default SidebarContainer;
