import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions/profile.action';
import * as businessProfileAction from '../actions/business-profile.action';
import * as api from '../../api/profile.api';

function* getProfile() {
  try {
    const result = yield call(api.getProfile);

    if (result) {
      yield put(actions.getProfileSuccess(result));
    } else {
      yield put(actions.getProfileFailure(result));
    }
  } catch (error) {
    yield put(actions.getProfileFailure(error));
  }
}

const watchGetProfile = [
  takeEvery(
    businessProfileAction.Types.CREATE_BUSINESS_PROFILE_SUCCESS,
    getProfile
  ),
  takeEvery(
    businessProfileAction.Types.UPDATE_BUSINESS_PROFILE_SUCCESS,
    getProfile
  ),
  takeEvery(actions.Types.GET_PROFILE_REQUEST, getProfile),
];

export default watchGetProfile;
