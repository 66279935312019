import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions/credit-card.action';
import * as api from '../../api/credit-card.api';

function* getAllCards() {
  try {
    const result = yield call(api.getAllCards);

    if (result) {
      yield put(actions.getAllCardsSuccess(result));
    } else {
      yield put(actions.getAllCardsFailure(result));
    }
  } catch (error) {
    yield put(actions.getAllCardsFailure(error));
  }
}

function* createCard(action) {
  try {
    const result = yield call(api.createCard, action.payload);

    if (result) {
      yield put(actions.createCardSuccess(result));
    } else {
      yield put(actions.createCardFailure(result));
    }
  } catch (error) {
    yield put(actions.createCardFailure(error));
  }
}

function* deleteCard(action) {
  try {
    const result = yield call(api.deleteCard, action.payload);

    if (result) {
      yield put(actions.deleteCardSuccess(result));
    } else {
      yield put(actions.deleteCardFailure(result));
    }
  } catch (error) {
    yield put(actions.deleteCardFailure(error));
  }
}

function* setDefaultCard(action) {
  try {
    const result = yield call(api.setDefaultCard, action.payload);

    if (result) {
      yield put(actions.setDefaultCardSuccess(result));
    } else {
      yield put(actions.setDefaultCardFailure(result));
    }
  } catch (error) {
    yield put(actions.setDefaultCardFailure(error));
  }
}

function* getSingleCard(action) {
  try {
    const result = yield call(api.getSingleCard, action.payload);

    if (result) {
      yield put(actions.getSingleCardSuccess(result));
    } else {
      yield put(actions.getSingleCardFailure(result));
    }
  } catch (error) {
    yield put(actions.getSingleCardFailure(error));
  }
}

const watchCreditCards = [
  takeEvery(actions.Types.GET_ALL_CARDS_REQUEST, getAllCards),
  takeEvery(actions.Types.CREATE_CARD_REQUEST, createCard),
  takeEvery(actions.Types.DELETE_CARD_REQUEST, deleteCard),
  takeEvery(actions.Types.GET_SINGLE_CARD_REQUEST, getSingleCard),
  takeEvery(actions.Types.SET_DEFAULT_CARD_REQUEST, setDefaultCard),
];

export default watchCreditCards;
