import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions/invoice.action';
import * as api from '../../api/invoice.api';

function* getInvoices() {
  try {
    const result = yield call(api.getInvoices);

    if (result) {
      yield put(actions.getInvoicesSuccess(result));
    } else {
      yield put(actions.getInvoicesFailure(result));
    }
  } catch (error) {
    yield put(actions.getInvoicesFailure(error));
  }
}

const watchGetInvoice = [
  takeEvery(actions.Types.GET_INVOICES_REQUEST, getInvoices),
];

export default watchGetInvoice;
