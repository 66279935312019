import request from '../utils/http.service';

export const getAllCards = async () => {
  let card = null;
  try {
    const res = await request('/me/credit-cards', 'GET');
    card = res.data;
  } catch (err) {
    card = err.data;
  }

  return card;
};

export const createCard = async (param) => {
  let card = null;
  try {
    const res = await request('/me/credit-cards', 'POST', param);
    card = res.data;
  } catch (err) {
    card = err.data;
  }

  return card;
};

export const deleteCard = async (param) => {
  let card = null;
  try {
    const res = await request(`/me/credit-cards/${param}`, 'DELETE');
    card = res.data;
  } catch (err) {
    card = err.data;
  }

  return card;
};

export const getSingleCard = async (param) => {
  let card = null;
  try {
    const res = await request(`/me/credit-cards/${param}`, 'GET');
    card = res.data;
  } catch (err) {
    card = err.data;
  }

  return card;
};

export const setDefaultCard = async (param) => {
  let card = null;
  try {
    const res = await request(
      `/me/credit-cards/${param}/set-default`,
      'PUT',
      param
    );
    card = res.data;
  } catch (err) {
    card = err.data;
  }

  return card;
};
