export const Types = {
  GET_ALL_CARDS_REQUEST: 'GET_ALL_CARDS_REQUEST',
  GET_ALL_CARDS_SUCCESS: 'GET_ALL_CARDS_SUCCESS',
  GET_ALL_CARDS_FAILURE: 'GET_ALL_CARDS_FAILURE',

  CREATE_CARD_REQUEST: 'CREATE_CARD_REQUEST',
  CREATE_CARD_SUCCESS: 'CREATE_CARD_SUCCESS',
  CREATE_CARD_FAILURE: 'CREATE_CARD_FAILURE',

  DELETE_CARD_REQUEST: 'DELETE_CARD_REQUEST',
  DELETE_CARD_SUCCESS: 'DELETE_CARD_SUCCESS',
  DELETE_CARD_FAILURE: 'DELETE_CARD_FAILURE',

  GET_SINGLE_CARD_REQUEST: 'GET_SINGLE_CARD_REQUEST',
  GET_SINGLE_CARD_SUCCESS: 'GET_SINGLE_CARD_SUCCESS',
  GET_SINGLE_CARD_FAILURE: 'GET_SINGLE_CARD_FAILURE',

  SET_DEFAULT_CARD_REQUEST: 'SET_DEFAULT_CARD_REQUEST',
  SET_DEFAULT_CARD_SUCCESS: 'SET_DEFAULT_CARD_SUCCESS',
  SET_DEFAULT_CARD_FAILURE: 'SET_DEFAULT_CARD_FAILURE',
};

export const getAllCardsRequest = () => ({
  type: Types.GET_ALL_CARDS_REQUEST,
  payload: {},
});
export const getAllCardsSuccess = (data) => ({
  type: Types.GET_ALL_CARDS_SUCCESS,
  payload: data,
});
export const getAllCardsFailure = (data) => ({
  type: Types.GET_ALL_CARDS_FAILURE,
  payload: data,
});

export const createCardRequest = (data) => ({
  type: Types.CREATE_CARD_REQUEST,
  payload: data,
});
export const createCardSuccess = (data) => ({
  type: Types.CREATE_CARD_SUCCESS,
  payload: data,
});
export const createCardFailure = (data) => ({
  type: Types.CREATE_CARD_FAILURE,
  payload: data,
});

export const deleteCardRequest = (data) => ({
  type: Types.DELETE_CARD_REQUEST,
  payload: data,
});
export const deleteCardSuccess = (data) => ({
  type: Types.DELETE_CARD_SUCCESS,
  payload: data,
});
export const deleteCardFailure = (data) => ({
  type: Types.DELETE_CARD_FAILURE,
  payload: data,
});

export const getSingleCardRequest = (data) => ({
  type: Types.GET_SINGLE_CARD_REQUEST,
  payload: data,
});
export const getSingleCardSuccess = (data) => ({
  type: Types.GET_SINGLE_CARD_SUCCESS,
  payload: data,
});
export const getSingleCardFailure = (data) => ({
  type: Types.GET_SINGLE_CARD_FAILURE,
  payload: data,
});

export const setDefaultCardRequest = (data) => ({
  type: Types.SET_DEFAULT_CARD_REQUEST,
  payload: data,
});
export const setDefaultCardSuccess = (data) => ({
  type: Types.SET_DEFAULT_CARD_SUCCESS,
  payload: data,
});
export const setDefaultCardFailure = (data) => ({
  type: Types.SET_DEFAULT_CARD_FAILURE,
  payload: data,
});
