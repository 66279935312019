import { Types } from '../actions/profile.action';

const initialState = {
  profile: null,
  invoices: null,
  loading: true,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        loading: false,
      };
    case Types.GET_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case Types.REMOVE_PROFILE_STATE:
      return {
        profile: null,
        invoices: null,
        loading: true,
      };

    default:
      return state;
  }
};

export default authReducer;
