import { Types } from '../actions/business-profile.action';

const initialState = {
  profile: null,
  error: null,
  loading: false,
};

const businessProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.CREATE_BUSINESS_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case Types.CREATE_BUSINESS_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        loading: false,
      };

    case Types.CREATE_BUSINESS_PROFILE_FAILURE:
      if (action.payload) {
        return {
          ...state,
          error: action.payload.response.status
            ? action.payload.response.status
            : action.payload,
          loading: false,
        };
      } else {
        return {
          ...state,
          error: 404,
          loading: false,
        };
      }

    case Types.UPDATE_BUSINESS_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case Types.UPDATE_BUSINESS_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        loading: false,
      };

    case Types.UPDATE_BUSINESS_PROFILE_FAILURE:
      if (action.payload) {
        return {
          ...state,
          error: action.payload.response.status
            ? action.payload.response.status
            : action.payload,
          loading: false,
        };
      } else {
        return {
          ...state,
          error: null,
          loading: false,
        };
      }

    case Types.GET_BUSINESS_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case Types.GET_BUSINESS_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        loading: false,
      };

    case Types.GET_BUSINESS_PROFILE_FAILURE:
      if (!action.payload) {
        return {
          ...state,
          error: action.payload.response.status
            ? action.payload.response.status
            : action.payload,
          loading: false,
        };
      } else {
        return {
          ...state,
          error: null,
          loading: false,
        };
      }

    default:
      return state;
  }
};

export default businessProfileReducer;
