import React from 'react';
import ImageCardContainer from './index.style';

const ImageCard = ({ image, removeImage, ...props }) => {
  return (
    <ImageCardContainer
      className="brand-image position-relative d-flex mx-2"
      {...props}
    >
      <img width="120" height="120" src={image.dataUri} alt="avatar" />
      <span
        className="remove-image rounded-circle position-absolute align-items-center justify-content-center"
        onClick={removeImage}
      >
        ⨉
      </span>
    </ImageCardContainer>
  );
};

export default ImageCard;
