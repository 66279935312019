import { Types } from '../actions/stripe-setting.action';

const initialState = {
  stripeSetupLink: null,
  stripeLoginLink: null,
  loading: false,
};

const stripeReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_STRIPE_SETUP_LINK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_STRIPE_SETUP_LINK_SUCCESS:
      return {
        ...state,
        stripeSetupLink: action.payload,
        loading: false,
      };
    case Types.GET_STRIPE_SETUP_LINK_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case Types.GET_STRIPE_LOGIN_LINK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_STRIPE_LOGIN_LINK_SUCCESS:
      return {
        ...state,
        stripeLoginLink: action.payload,
        loading: false,
      };
    case Types.GET_STRIPE_LOGIN_LINK_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default stripeReducer;
