export const Types = {
  GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

  GET_INVOICES_REQUEST: 'GET_INVOICES_REQUEST',
  GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
  GET_INVOICES_FAILURE: 'GET_INVOICES_FAILURE',

  REMOVE_PROFILE_STATE: 'REMOVE_PROFILE_STATE',
};

export const getProfileRequest = () => ({
  type: Types.GET_PROFILE_REQUEST,
  payload: {},
});
export const getProfileSuccess = (data) => ({
  type: Types.GET_PROFILE_SUCCESS,
  payload: data,
});
export const getProfileFailure = (data) => ({
  type: Types.GET_PROFILE_FAILURE,
  payload: data,
});

export const getInvoicesRequest = () => ({
  type: Types.GET_INVOICES_REQUEST,
  payload: {},
});
export const getInvoicesSuccess = (data) => ({
  type: Types.GET_INVOICES_SUCCESS,
  payload: data,
});
export const getInvoicesFailure = (data) => ({
  type: Types.GET_INVOICES_FAILURE,
  payload: data,
});

export const removeProfileState = () => ({
  type: Types.REMOVE_PROFILE_STATE,
  payload: {},
});
