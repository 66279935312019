import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions/pricing.action';
import * as api from '../../api/pricing.api';

function* getPrices() {
  try {
    const result = yield call(api.getPrices);

    if (result) {
      yield put(actions.getPricesSuccess(result));
    } else {
      yield put(actions.getPricesFailure(result));
    }
  } catch (error) {
    yield put(actions.getPricesFailure(error));
  }
}

export function* watchGetPrices() {
  yield takeEvery(actions.Types.GET_PRICES_REQUEST, getPrices);
}
