const theme = {
  baseBorderRadius: 4,
  colors: {
    white: '#fff',
    black: '#000',
    red: '#ff2d55',
    yellow: '#F7D256',
    grey: '#ACB1C0',
    green: '#6FCF97',
    lightGrey: '#F7F8FA',
    persianRed: '#BE1825',
    primaryDark: '#1e2432',
    darkRed: '#9F1C25',
    persianBlue: '#2F80ED',
  },
};

export default theme;
