import { css } from '@emotion/react';

export default css`
  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

  body {
    margin: 0;
    font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    background-color: #f7f8fa;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  :focus {
    outline: none;
  }

  button:focus,
  button:active,
  .btn:focus,
  .btn.focus {
    box-shadow: none;
    outline: none;
  }

  a {
    text-decoration: none;
  }

  .main {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    min-width: 0;
    min-height: 100vh;
    transition: margin 0.3s;
  }

  .main {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    min-width: 0;
    min-height: 100vh;
    transition: margin 0.3s;
    margin-left: 0;

    &.side-open {
      @media (min-width: 1024px) {
        margin-left: 16rem;
      }
    }
  }

  .content {
    flex: 1;
    margin-top: 4rem;
    padding: 1.5rem 1.25rem 1.25rem;
    position: relative;

    @media (min-width: 768px) {
      padding: 2rem;
    }
  }

  button.action-control {
    min-width: 6.5rem;
  }

  // Scrollbar customize

  /* Customize website's scrollbar like Mac OS
    Not supports in Firefox and IE */

  /* total width */
  body::-webkit-scrollbar {
    background-color: #fff;
    width: 10px;
  }

  /* background of the scrollbar except button or resizer */
  body::-webkit-scrollbar-track {
    background-color: #fff;
  }

  /* scrollbar itself */
  body::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 14px;
    border: 2px solid #fff;
  }

  /* set button(top and bottom of the scrollbar) */
  body::-webkit-scrollbar-button {
    display: none;
  }

  // React toast notification
  .react-toast-notifications__container {
    z-index: 1050 !important;
    top: 4.5rem !important;
  }

  .react-tel-input {
    .form-control {
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      line-height: 1.5;
      font-size: 1rem;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
    }

    .selected-flag {
      width: 42px;
    }
  }

  .pac-container {
    z-index: 2000 !important;
  }
`;
