import styled from '@emotion/styled';

const ImageCardContainer = styled.div`
  border-radius: 8px;

  img {
    border-radius: 1rem;
  }

  &:hover {
    .remove-image {
      display: flex;
    }
  }

  .remove-image {
    display: none;
    top: 0rem;
    right: 0rem;
    width: 1.3rem;
    height: 1.3rem;
    background-color: #eee;
    cursor: pointer;
    color: ${(props) => props.theme.colors.red};
    opacity: 1;
    font-size: 0.65rem;
    font-weight: bold;

    &:hover {
      opacity: 0.85;
      background-color: #dcdcdc;
    }
  }
`;

export default ImageCardContainer;
