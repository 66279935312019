export const Types = {
  GET_BILL_INFO_REQUEST: 'GET_BILL_INFO_REQUEST',
  GET_BILL_INFO_SUCCESS: 'GET_BILL_INFO_SUCCESS',
  GET_BILL_INFO_FAILURE: 'GET_BILL_INFO_FAILURE',

  UPDATE_BILL_INFO_REQUEST: 'UPDATE_BILL_INFO_REQUEST',
  UPDATE_BILL_INFO_SUCCESS: 'UPDATE_BILL_INFO_SUCCESS',
  UPDATE_BILL_INFO_FAILURE: 'UPDATE_BILL_INFO_FAILURE',
};

export const getBillInfoRequest = () => ({
  type: Types.GET_BILL_INFO_REQUEST,
  payload: {},
});
export const getBillInfoSuccess = (data) => ({
  type: Types.GET_BILL_INFO_SUCCESS,
  payload: data,
});
export const getBillInfoFailure = (data) => ({
  type: Types.GET_BILL_INFO_FAILURE,
  payload: data,
});

export const updateBillInfoRequest = (data) => ({
  type: Types.UPDATE_BILL_INFO_REQUEST,
  payload: data,
});
export const updateBillInfoSuccess = (data) => ({
  type: Types.UPDATE_BILL_INFO_SUCCESS,
  payload: data,
});
export const updateBillInfoFailure = (data) => ({
  type: Types.UPDATE_BILL_INFO_FAILURE,
  payload: data,
});
